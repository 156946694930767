<template>
  <OneColumnTemplate>
    <template v-slot:ColumnContent>
      <div class="PageContentWrapper">
        <div class="Page-Content">
          <span class="OctaS_PageTitleDefault">Новости</span>

          <SortingWithFiltersPreset
            class="SFPRestyle"
            title=""
            searchPlaceholder="Поиск"
            :searchAttribures="['title', 'desc']"
            :originalArray="News.List"
            :setNewArrayCallback="handleArrayUpdate"
            :sortMethodCallback="handleSort"
            :filtersButtons="[]"
            :sortOptions="[{ text: 'Сортировка по умолчанию', val: 'default' }]"
            :filterButtonModeServer="false"
            :filterButtonParentFilter="handleFilter"
            :postFiltersDataChanges="handleFilterChanges"
            :setFiltersData="{
              button: Filters.button,
              search: Filters.search,
              sorting: Filters.sorting,
            }"
            :key="'SortingFiltersSearch-' + news_hash"
          />

          <NewsList
            class="NewsRestyle"
            :useReadyList="true"
            :readyList="News.List_Filtered"
            :key="`NewsFiltered-${news_filtered_hash}`"
          />
        </div>
      </div>
    </template>
  </OneColumnTemplate>
</template>

<script>
import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import OneColumnTemplate from "@/components/ProfileTemplates/OneColumn.vue";
import NewsList from "@/components/Profile/NewsList.vue";

import { getNewsByFilters } from "@/api/news.js";

export default {
  name: "NewsPage",
  components: {
    SortingWithFiltersPreset,
    OneColumnTemplate,
    NewsList,
  },
  data() {
    return {
      Filters: {
        button: null,
        search: "",
        sorting: "default",
      },
      News: {
        List: [],
        List_Filtered: [],
      },
    };
  },
  async mounted() {
    let _news_req = await getNewsByFilters();
    this.News.List = _news_req.data;
  },
  computed: {
    news_hash() {
      let a = this.News.List.map(
        (x, xindx) => `${x.id}_${x?.link_name}_${xindx}`
      );
      return `${this.News.List?.length}-${a.join("-")}`;
    },

    news_filtered_hash() {
      let a = this.News.List_Filtered.map(
        (x, xindx) => `${x.id}_${x?.link_name}_${xindx}`
      );
      return `${this.News.List_Filtered?.length}-${a.join("-")}`;
    },
  },
  methods: {
    handleArrayUpdate(UpdatedArray) {
      this.News.List_Filtered = UpdatedArray;
    },
    handleSort(SortName, Array) {
      return Array;
    },
    handleFilter(val, array) {
      return array;
    },
    handleFilterChanges(obj) {
      this.Filters.button = obj.button;
      this.Filters.search = obj.search;
      this.Filters.sorting = obj.sorting;
    },
  },
};
</script>

<style scoped>
.PageContentWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 44px;

  width: 100%;
  height: 100%;

  padding-right: 15px;

  overflow: auto;
}
.Page-Content {
  position: relative;
  display: flex;

  margin: 0% auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;

  width: 100%;
  max-width: 1150px;
  height: fit-content;
}

.SFPRestyle:deep(.FiltersHeader) {
  display: none;
}
.SFPRestyle:deep(.ResetFilters) {
  display: none;
}
.SFPRestyle:deep(.Filters) {
  flex-direction: row-reverse;
}

@media (max-width: 992px) {
  .PageContentWrapper {
    padding-right: 0px;
    padding-bottom: 70px;
  }

  .NewsRestyle:deep(.NB-Desc) {
    -webkit-line-clamp: 4;
  }

  .NewsRestyle:deep(.News-Block) {
    gap: 8px;
  }
  .NewsRestyle:deep(.NB-HeadnDesc) {
    gap: 16px;
  }
}
</style>
